.modal-message {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .6);
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999999;

    .modal {
        max-width: 855px;
        width: 100%;
        padding: 50px 45px;
        background-color: #ffffff;
        .content {
            .icons {
                display: flex;
                justify-content: space-between;
                margin: 0 0 10px 0 !important;

                img {
                    transition: .3s ease-in-out transform;

                    &:first-child {
                        width: 30px;
                    }
                    &:last-child {
                        width: 25px;
                        cursor: pointer;
                    }
                }
            }
            .text {
                max-width: 552px;
                width: 100%;
                h2 {
                    color: #39637b;
                }
                p {
                    margin-top: 5px;
                    margin-bottom: 16px;
                    line-height: 27px;
                    font-size: 16px; 
                }
                .reset-margin {
                    margin: 0;
                    line-height: 1;
                }
            }
        }
    }
}