.contact {
    // margin: 95px 0 80px 0;
    // @media( max-width: 1024px ) {
    //     margin: 45px 0 45px 0;
    // }
    margin: 55px 0 60px 0;
    @media( max-width: 1024px ) {
        margin: 45px 0 45px 0;
    }
    .header {
        margin-bottom: 40px;
        @media( max-width: 1024px ) {
            margin: 30px 0;
        }
    }
    .btn-wrapper {
        margin: 50px 0;
        @media( max-width: 1024px ) {
            margin: 30px 0;
        }
    }
    .table-wrapper {
        @media( max-width: 800px ) {
            overflow-y: auto;
            .row {
                width: 800px;
            }
        }
        .row {
            display: flex;
            background-color: #ffffff;
            &:nth-of-type(even) {
                background-color: #fcfcfc;
            }
            &:first-of-type {
                background-color: #f5f7f8;
                .col {
                    p, a {
                        color: #39637b;
                    }
                }
            }
            .col {
                display: flex;
                justify-content: center;
                flex-direction: column;
                max-width: 300px;
                width: 100%;
                // height: 70px;
                height: 55px;
                padding: 10px 15px;
                p,a {
                    margin: 0 0 5px 0;
                    line-height: 1;
                    text-decoration: none;
                    color: #74726B;
                    font-size: 16px;
                    &:last-of-type {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}