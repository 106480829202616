.checkbox {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 8px;
    position: relative;

    &.blue-text {
        color: #39637b;
    }
    &.add-margin-bottom {
        margin-bottom: 20px;
    }
    .tip {
        margin-left: 18px;
        width: 16px;
        cursor: pointer;
        position: absolute;
        top: 4px;
        right: -20px;

        @media(max-width: 550px) {
            right: 0;
        }
    }
    .check {
        width: 30px;
        height: 30px;
        border: 1px solid #9D9B96;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 20px;
        cursor: pointer;

        img {
            width: 22.4px;
        }
    }
    .text-editor {
        display: flex;
        align-items: center;
        width: calc(100% - 30px - 20px);
        font-family: 'Helvetica Neue LT Pro';
        font-size: 16px;
        a {
            color: #74726B;
        }
    }

    .more-or-less {
        display: inline-block;
        color: #39637b;
        cursor: pointer;
        margin: 0;
    }

    &.error {
        
        .check {
            border: 1px solid #B41C1C!important;
        }
        .error-message {
            color: #B41C1C!important;
            font-size: 14px;
            margin-left: 15px;
            margin-top: 1px;
        }
    }
}