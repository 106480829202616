.btn-wrap {
    display: flex;
    align-items: center;

    &.have-tip {
        @media(min-width: 706px) {
            max-width: 339px!important;
        }
    }
    .tip {
        position: relative;
        margin-left: 18px;
        width: 20px;
        // top: calc(50% - 10px);
        cursor: pointer;
    }
    .btn {
        font-size     : 12px;
        padding       : 19px 30px;
        cursor        : pointer;
        transition    : .2s ease-in-out all;
        // max-width     : 313px;
        max-width     : 315px;
        width         : 100%;
        font-size     : 18px;
        // text-transform: uppercase;
        text-align    : center;
        @media( max-width: 1024px ) {
            padding: 15px 30px;
        }

        &:hover {
            box-shadow: 0 6px 6px 0 rgba(64,99,126,0.49), 0 10px 20px 0 rgba(64,99,126,.19);
        }
        
        &.reverse {
            display: flex;
            flex-direction: row-reverse;
        }
        &.blue {
            background-color: #39637b;
            color           : #ffffff;

            &.border-white {
                border          : 1px solid #ffffff;
            }
        }

        &.blue-cookies {
            padding         : 15px 30px;
            background-color: #39637b;
            color           : #ffffff;

            &.border-white {
                border          : 1px solid #ffffff;
            }
        }

        &.disabled {
            background-color: #6e6e70;

            &:hover {
                box-shadow: none;
            }
        }
        &.white {
            background-color: #ffffff;
            color           : #39637b;
            border          : 1px solid #39637b;
            padding         : 11px 35px;
        
        }
        
        
        .arrow {
            width       : 7px;
            height      : 15px;
            margin-left : 22px;
            position    : relative;
            top         : 2px;
            transition  : .2s ease-in-out transform;
        }
        .arrow-prev {
            width       : 7px;
            height      : 15px;
            margin-right: 22px;
            position    : relative;
            top         : 0px;
            transition  : .2s ease-in-out transform;
        }
        .upload-ico {
            width       : 16px;
            height      : 17px;
            margin-left : 22px;
            position    : relative;
            transition  : .2s ease-in-out transform;
        }
        .add {
            width       : 13px;
            height      : 13px;
            margin-right : 22px;
            top         : 2px;
            position    : relative;
            transition  : .2s ease-in-out transform;
        }
        .minus {
            width       : 13px;
            height      : 1px;
            margin-right : 22px;
            top         : 6px;
            position    : relative;
            transition  : .2s ease-in-out transform;
        }
    }
}
