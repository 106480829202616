.form {
    .header {
        h3 {
            margin-bottom: 25px;
        }
        p {
            margin-bottom: 35px;
        }
    }
    .form-inputs {
        .input-row {
            display: flex;
            max-width: 100%;
            width: 100%;
            margin: 0 -15px;
            @media( max-width: 1380px ) {
                flex-wrap: wrap;
            }
            .col {
                max-width: 312.5px;
                min-width: 312.5px;
                width: 100%;
                margin: 0 15px;
                margin-bottom: 40px;
                @media( max-width: 1024px ) {
                    margin-bottom: 30px;
                }
                @media( max-width: 500px ) {
                    margin-bottom: 20px;
                }
            }
        }
        .checkbox-row {
            margin: 20px 0 0 0;
            &.big-margin {
                margin-top: 25px !important;
                @media( max-width: 1024px ) {
                    margin-top: 15px !important;
                }
            }
        }
        small {
            display: block;
            font-size: 14px;
            margin: 40px 0;
            @media( max-width: 1024px ) {
                margin: 30px 0;
                font-size: 12px;
            }
            @media( max-width: 500px ) {
                margin: 20px 0;
            }
            a {
                color: rgb(57, 99, 123);
            }
        }
        .btn-wrapper {
            margin-bottom: 40px;
        }
    }
}