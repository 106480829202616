header {
    
    .logo {
        @media(max-width: 515px) {
            display: flex;
            flex-direction: column;
        }
        img {
            cursor: pointer;
            // width: 100%;
            &.grenke {
                margin-right: 55px;
                width: 225px;
                @media (max-width: 600px) {
                    width: 130px;
                    margin-right: 20px;
                }
            }
            &.ab {
                width: 120px;
                @media (max-width: 600px) {
                    width: 100px;
                }
                @media(max-width: 515px) {
                    margin-top: 10px;
                }
            }
        }
    }

}