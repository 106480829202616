.icons {
    // margin: 85px 0 95px 0;
    margin: 85px 0 55px 0;
    @media(max-width: 1024px) {
        margin: 55px 0 45px 0;
    }
    .icons-wrapper {
        display: flex;
        justify-content: space-between;
        margin: 50px 35px 0 35px;
        @media( max-width: 1200px ) {
            flex-wrap: wrap;
            justify-content: initial;
            margin: 50px 0 0 0;
        }
        .icon {
            text-align: center;
            @media( max-width: 1360px ) {
                margin: 0 15px;
            }
            @media( max-width: 1024px) {
                max-width: calc(25% - 30px);
                width: 100%;
            }
            @media( max-width: 700px) {
                max-width: calc(33.3% - 30px);
            }
            @media( max-width: 500px ) {
                max-width: calc(50% - 15px);
                &:nth-of-type(odd) {
                    margin-left: 0;
                }
                &:nth-of-type(even) {
                    margin-right: 0;
                }
            }
            .icon-img {
                height: 70px;
            }
        }
    }
}