.calculator {
    margin: 75px 0 60px 0;
    @media( max-width: 1024px ) {
        margin: 45px 0 45px 0;
    }
    .header {
        margin-bottom: 40px;
        @media( max-width: 1024px ) {
            margin: 30px 0;
        }
    }
    .btn-wrapper {
        margin: 50px 0;
        @media( max-width: 1024px ) {
            margin: 30px 0;
        }
    }
}