footer {
    padding: 30px 0;
    font-size: 14px;
    color: #74726B;
    border: 1px solid #F1EFEC;
    
    .container {
        display: flex;
        justify-content: flex-end;
        
        @media (max-width: 790px) {
            justify-content: center;
            flex-wrap: wrap;
        }        

        a, span {
            text-transform: uppercase;
            margin-right: 30px;
            text-decoration: none;
            color: #74726B;

            @media (max-width: 790px) {
                margin-right: 0px;
                width: 50%;
                text-align: center;
            }
        }
        span {
            cursor: pointer;
        }
        p {
            font-size: 14px;
            padding-left: 30px;
            border-left: 1px solid #D2D2D2;
            margin: 0;
            @media (max-width: 790px) {
                width: 100%;
                text-align: center;
                border-top: 1px solid #D2D2D2;
                padding-top: 20px;
                margin-top: 20px;
                padding-left: 0px;
                border-left: 0px solid #D2D2D2;
            }
            a {
                margin-right: 0;
            }
        }
    }
}