.form-item {
    height: 48px;
    position: relative;
    width: 100%;
    display: inline-block;

    &.xxl {
        max-width: 312.5px;
    }
    &.xl {
        max-width: 205px;
    }
    &.l {
        max-width: 114px;
    }
    &.s {
        max-width: 87px;
    }
    &.full {
        max-width: 100%;
    }

    &.small-textarea {
        textarea {
            min-height: 100px;
        }
    }

    textarea {
        width: 100%;
        min-height: 143px;
        padding: 16px;
        color: #74726B;
        font-family: 'Helvetica Neue LT Pro Roman';
        font-size: 16px;
        line-height: 24px;
        border: 1px solid #D2D2D2;
    }
    .tip {
        position: absolute;
        margin-left: 18px;
        width: 20px;
        // right: -37px;
        right: 20px;
        top: calc(50% - 10px);
        cursor: pointer;
    }
    label {
        font-family: 'Helvetica Neue LT Pro Roman';

        &.focus, &.error, &.filled {
            .placeholder {
                transform: translateY(-29px);
                font-size: 12px;
                text-transform: uppercase;
            }
        }
        &.disabled-force {
          
            .placeholder {
                transform: translateY(0);
                font-size: initial;
                text-transform: initial;
                .text {
                    color: #9D9C92!important;
                }
                .underline {
                    background-color: transparent;
                }
            }
            input {
                background-color: #F1EFEC;
            }
        }
        &.filled {
            .placeholder {
                .text {
                    color: #9E9C96!important;
                }
                .underline {
                    bottom: -1px;
                }
            }
            input {
                border: 1px solid #D2D2D2;
            }
            &.disabled {
                .placeholder {
                    .text {
                        color: #D2D2D2!important;
                    }
                }
                input {
                    border: 1px solid #D2D2D2;
                }
            }
        }
        &.error {
            .placeholder {
                .text {
                    color: #B41C1C!important
                }
            }
            input, textarea {
                border: 1px solid #B41C1C!important;
            }
            .error-message {
                color: #B41C1C!important;
                font-size: 14px;
                padding-left: 14px;
                font-family: 'Helvetica Neue LT Pro Roman';
                
            }
        }
        &.disabled {
            .placeholder {
                .text {
                    color: #9E9C96!important;
                }
                .underline {
                    bottom: -1px;
                    background-color: #F1EFEC;

                }
            }
            input {
                border: 1px solid #D2D2D2!important;
                background-color: #F1EFEC;
            }
        }
        &.focus {
            .placeholder {
                .text {
                    color: #39637b
                }
            }
            input {
                border: 1px solid #39637b;
            }
            &.filled {
                .placeholder {
                    .text {
                        color: #9E9C96!important;
                    }
                }
            }
        }
        .disabled {
            cursor: default!important;
        }
        input {
            width: 100%;
            height: 100%;
            background-color: #fff;
            border: 1px solid #D2D2D2;
            outline: none;
            padding: 0 15px;
            color: #74726B;
            font-family: 'Helvetica Neue LT Pro Roman';
            font-size: 16px;
            -webkit-appearance: none;
            border-radius: 0;
            &:disabled {
                cursor: default;
            }
        }
        .calendar {
            width: 16.4px;
            position: absolute;
            right: 15px;
            top: 15px;
            cursor: pointer;
            
        }
        .placeholder {
            position: absolute;
            top: 32%;
            left: 15px;
            font-size: 16px;
            font-family: 'Helvetica Neue LT Pro Roman';
            transition: .2s ease-in-out all;
            
            .underline {
                content: "";
                height: 2px;
                width: 100%;
                position: absolute;
                bottom: -1px;
                left: 0px;
                background-color: #fff;
                z-index: 1;

            }
            .text {
                color: #9D9C92;
                position: relative;
                z-index: 2;
                font-family: 'Helvetica Neue LT Pro';
            }
        }
    }
    &:hover {
        .hidden {
            display: flex;
            align-items: center;
        }
    }

    .hidden {
        font-family: 'Helvetica Neue LT Pro';
        position: absolute;
        left: 0;
        bottom: 53px;
        background-color: #ffffff;
        padding: 10px 20px;
        width: auto;
        font-size: 14px;
        line-height: 20px;
        height: auto;
        border: 1px solid #74726B;
        display: none;
        z-index: 999999;
    }
    
}
