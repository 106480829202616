.main-banner {
    height          : 210px;
    width           : 100%;
    position        : relative;
    z-index         : 2;
    margin-bottom   : 85px;
    @media( max-width: 1024px ) {
        margin-bottom: 65px;
    }
    @media(max-width: 680px) {
        height: 130px;
    }

    img.banner {
        width   : 100%;
        height  : 100%;
        object-fit: cover;

        @media(max-width: 680px) {
            object-position: 53% 50%;
        }
    }

    .box {
        display: flex;
        align-items: center;
        position: absolute;
        left: 267px;
        bottom: -35px;
        background-color: #78a290;
        max-width: 650px;
        min-height: 70px;
        width: 100%;
        padding: 18px 0 18px 50px;

        @media (max-width: 1500px) {
            left: 60px;
        }
        @media (max-width: 944px) {
            left: 15px;
        }
        @media (max-width: 780px) {
            max-width: 500px;
            padding: 15px 0 15px 20px;
        }
        @media (max-width: 550px) {
            max-width: 450px;
            left: 0;
            min-height: 52px;
            bottom: -26px;
        }

        h1 {
            color: #ffffff;
            text-transform: uppercase;
            font-size: 28px;
            line-height: 1;
            @media (max-width: 1024px) {
                font-size: 25px;
            }
            @media (max-width: 550px) {
                font-size: 22px;
            }
            @media (max-width: 450px) {
                font-size: 20px;
            }
            @media (max-width: 380px) {
                font-size: 18px;
            }
        }
    }
}