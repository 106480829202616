header {
    padding         : 35px 0;
    background-color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999;
    width: 100%;
    -webkit-box-shadow: 1px 2px 13px -2px #000000; 
    box-shadow: 1px 2px 13px -2px #000000;
    @media(max-width: 936px) {
        padding: 20px 0;
    }
    @media(max-width: 515px) {
        padding: 10px 0;
    }

    .prev-calc {
        font-size     : 18px;
        color         : #74726b;
        text-transform: uppercase;
        cursor        : pointer;

        @media(max-width: 936px) {
            font-size: 16px;
            margin-top: 10px;
        }
    }

    .container {
        max-width       : 1820px;
        width           : 100%;
        margin          : 0 auto;
        display         : flex;
        justify-content : space-between;
        align-items     : center;
        padding         : 0 10px;
    }

    .logos-col {
        display: flex;
        align-items: center;

        .shop-logo {
            margin-left: 90px;  
            @media(max-width: 936px) {
                margin-left: 30px;
            }
            @media(max-width: 515px) {
                margin-left: 0;
                margin-top: 10px;
            }
        }
    }

    .info-col {
        display: flex;

        @media(max-width: 936px) {
            flex-direction: column;
        }

        .info {
            display: flex;

            @media(max-width: 936px) {
                justify-content: center;
            }
            &.margin-reset {
                a {
                    margin-right: 0;
                }
            } 

            img {
                width: 26px;
                margin-right: 7px;
            }

            a {
                color: #74726b;
                text-decoration: none;
                display: block;
                margin-right: 55px;
                font-size: 16px;
                
                @media(max-width: 936px) {
                    font-size: 16px;
                    text-align: center;
                    margin-right: 0;
                }
            }
        }
    }
}
