@font-face {
    font-family: 'Helvetica Neue LT Pro Roman';
    src: local('Helvetica Neue LT Pro 55 Roman'), local('HelveticaNeueLTPro-Roman'),
        url('./../fonts/HelveticaNeueLTPro-Roman.woff2') format('woff2'),
        url('./../fonts/HelveticaNeueLTPro-Roman.woff') format('woff');
        font-weight: normal;
        font-style: normal;
}

@font-face {
    font-family: 'Helvetica Neue LT Pro';
    src: local('Helvetica Neue LT Pro 45 Light'), local('HelveticaNeueLTPro-Lt'),
        url('./../fonts/HelveticaNeueLTPro-Lt.woff2') format('woff2'),
        url('./../fonts/HelveticaNeueLTPro-Lt.woff') format('woff');
        font-weight: 300;
        font-style: normal;
}

@font-face {
    font-family: 'Helvetica';
    src: local('Helvetica'),
        url('./../fonts/subset-Helvetica.woff2') format('woff2'),
        url('./../fonts/subset-Helvetica.woff') format('woff'),
        url('./../fonts/subset-Helvetica.ttf') format('truetype');
        font-weight: 500;
        font-style: normal;
}

@font-face {
    font-family: 'Helvetica Light';
    src: local('Helvetica Light'),
        url('./../fonts/HelveticaNeueLight.woff2') format('woff2');
        font-weight: 450;
        font-style: normal;
}

* {
    box-sizing: border-box;
    outline: none;
}
body {
    margin: 0;
    font-family:'Helvetica Neue LT Pro Roman', 'sans-serif';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #74726B;
}
h1, h2, h3, h4, h5 {
    margin: 0;
    font-family: 'Helvetica Neue LT Pro';
    font-weight: 300;
    color: #39637b;
}

h1 {
    font-size: 72px;
    line-height: 86px;    
    @media( max-width: 1024px ) {
        font-size: 54px;
        line-height: 1;
    }
    @media( max-width: 800px ) {
        font-size: 48px;
    }
    @media( max-width: 500px ) {
        font-size: 42px;
    }
}

h2 {
    font-size: 35px;
    line-height: 48px;
    @media( max-width: 1024px ) {
        font-size: 32px;
        line-height: 1;
    }
    @media( max-width: 800px ) {
        font-size: 30px;
    }
    @media( max-width: 500px ) {
        font-size: 28px;
    }
}

h3 {
    font-size: 28px;
    line-height: 48px;
    @media( max-width: 1024px ) {
        font-size: 26px;
        line-height: 1;
    }
    @media( max-width: 800px ) {
        font-size: 24px;
    }
    @media( max-width: 500px ) {
        font-size: 22px;
    }
}

p {
    font-size: 18px;
    margin: 15px 0;
    @media( max-width: 500px ) {
        font-size: 16px;
    }
}

.ghost {
    height: 117px;
    width: 100%;
    @media( max-width: 936px ) {
        height: 87px;
    }
    @media( max-width: 600px ) {
        height: 78px;
    }
}

.container {
    max-width: 1370px;
    padding: 0 15px;
    margin: 0 auto;
}